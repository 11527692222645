import React, { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import store, { persistor } from './store/configStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastProvider } from './hooks/useToast';
import Login from './hoc/Login/Login';
import Dashboard from './hoc/Dashboard/Dashboard';
import Sidebar from './components/main/Sidebar/Sidebar';
import './App.css';
import PrivateRoute from './components/utility/PrivateRoute';
import Influencer from "./hoc/Influencer/Influencer";
import Brand from "./hoc/Brand/Brand";
import Campaign from "./hoc/Campaign/Campaign";
import CampaignDetails from "./hoc/Campaign/CampaignDetails";

function App() {
    const [isOpen, setIsOpen] = useState(true);
    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const location = useLocation(); // Get the current path
    const isLoginPage = location.pathname === '/login'; // Check if the current page is the login page

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <div className="App min-h-screen bg-gray-100 flex">
                    <ToastProvider defaultPosition="top-center" defaultDuration={5000}>
                        {/* Conditionally render the sidebar if not on the login page */}
                        {!isLoginPage && <Sidebar isOpen={isOpen} />}

                        {/* Hamburger Icon */}
                        {!isLoginPage && (
                            <button
                                onClick={toggleSidebar}
                                className="fixed top-5 left-5 z-20 focus:outline-none"
                            >
                                <svg className={`w-8 h-8 ${isOpen ? 'text-gray-300' : 'text-gray-800'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            </button>
                        )}

                        <div className={`flex-1 transition-all duration-300 ${isOpen && !isLoginPage ? 'ml-64' : 'ml-0'} p-10`}>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route
                                    path="/"
                                    element={
                                        <PrivateRoute>
                                            <Dashboard />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/influencer"
                                    element={
                                        <PrivateRoute>
                                            <Influencer />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/brand"
                                    element={
                                        <PrivateRoute>
                                            <Brand />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/campaign"
                                    element={
                                        <PrivateRoute>
                                            <Campaign />
                                        </PrivateRoute>
                                    }
                                />
                                <Route
                                    path="/campaigndetails/:id"
                                    element={
                                        <PrivateRoute>
                                            <CampaignDetails />
                                        </PrivateRoute>
                                    }
                                />
                            </Routes>
                        </div>
                    </ToastProvider>
                </div>
            </PersistGate>
        </Provider>
    );
}

export default App;
