import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import Modal from 'react-modal';
import { brandList, brandListReset } from "../../store/actions/brandActions/brandListActions";
import {
    approvedRejectedBrand,
    approvedRejectedBrandReset
} from "../../store/actions/brandActions/approvedRejectedBrandActions";

Modal.setAppElement('#root'); // Set root element to prevent screen readers from reading the modal content.

function Brand() {
    const dispatch = useDispatch();
    const brandReducer = useSelector(state => state.brandListReducers);
    const approvedRejectedBrandReducer = useSelector(state => state.approvedRejectedBrandReducers)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [statusFilter, setStatusFilter] = useState('PENDING');

    useEffect(() => {
        dispatch(brandList({statusFilter:statusFilter}));
    }, [statusFilter]);

    useEffect(() => {
        if (brandReducer?.status === 200) {
            console.log("brand data", brandReducer.data);
            dispatch(brandListReset());
        } else if (brandReducer?.status) {
            dispatch(brandListReset());
        }
    }, [brandReducer]);

    useEffect(() => {
        if (approvedRejectedBrandReducer?.status === 200) {
            console.log("brand data", approvedRejectedBrandReducer.data);
            dispatch(brandList({statusFilter:statusFilter}));
            dispatch(approvedRejectedBrandReset());
        } else if (approvedRejectedBrandReducer?.status) {
            dispatch(approvedRejectedBrandReset());
        }
    }, [approvedRejectedBrandReducer]);

    const openApproveModal = (data) => {
        setSelectedBrand(data);
        setIsApproveModalOpen(true);
    };

    const openRejectModal = (data) => {
        setSelectedBrand(data);
        setIsRejectModalOpen(true);
    };

    const closeApproveModal = () => {
        setIsApproveModalOpen(false);
        setSelectedBrand(null);
    };

    const closeRejectModal = () => {
        setIsRejectModalOpen(false);
        setSelectedBrand(null);
    };

    const handleApprove = () => {
        if (selectedBrand) {
            console.log('Approved brand ID:', selectedBrand);
            dispatch(approvedRejectedBrand([{
                "brandId": selectedBrand.brandId,
                "status": "APPROVED"
            }]));
            closeApproveModal();
        }
    };

    const handleReject = () => {
        if (selectedBrand) {
            console.log('Rejected brand ID:', selectedBrand);
            dispatch(approvedRejectedBrand([{
                "brandId": selectedBrand.brandId,
                "status": "REJECTED"
            }]));
            closeRejectModal();
        }
    };

    const handleStatusFilterChange = (e) => {
        setStatusFilter(e.target.value);
    };

    const columns = [
        {
            name: 'Brand Id',
            selector: row => row.brandId,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Country',
            selector: row => row.country?.countryName?row.country?.countryName:'----',
            sortable: true,
        },
        {
            name: 'Phone Number',
            selector: row => row.phoneNumber?row.phoneNumber:'----',
            sortable: true,
        },
        ...(statusFilter === 'PENDING'
            ? [{
                name: 'Actions',
                width: '200px', // Set a specific width for the "Actions" column
                cell: row => (
                    <div className="flex items-center justify-center space-x-2 w-full">
                        <button
                            onClick={() => openApproveModal(row)}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Approve
                        </button>
                        <button
                            onClick={() => openRejectModal(row)}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-xl"
                        >
                            Reject
                        </button>
                    </div>
                ),
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }]
            : []),
    ];

    return (
        <div className="">
            <div className="text-4xl mb-4 leading-[40px]">
                Brand
            </div>

            <div className="mb-4 text-2xl flex items-center">
                <label className="mr-4 font-semibold text-gray-700">Filter by status:</label>
                <select
                    value={statusFilter}
                    onChange={handleStatusFilterChange}
                    className="border-2 border-gray-300 bg-gray-100 text-gray-700 rounded-lg px-4 py-2 hover:bg-gray-200 focus:outline-none transition-colors duration-300 ease-in-out"
                >
                    <option value="PENDING">PENDING</option>
                    <option value="APPROVED">APPROVED</option>
                    <option value="REJECTED">REJECTED</option>
                </select>
            </div>

            {Array.isArray(brandReducer?.data) && brandReducer.data.length > 0 ? (
                <DataTable
                    columns={columns}
                    data={brandReducer.data}
                />
            ) : (
                <div className="text-4xl leading-[70px] text-gray-500 text-center mt-4">
                    There are no records to display.
                </div>
            )}

            {/* Approval Confirmation Modal */}
            <Modal
                isOpen={isApproveModalOpen}
                onRequestClose={closeApproveModal}
                contentLabel="Confirm Approve"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                <div className="text-2xl font-bold">Confirm Approval</div>
                <p className="text-2xl">Are you sure you want to approve this brand?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleApprove}
                            className="bg-green-500 hover:bg-green-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeApproveModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>

            {/* Rejection Confirmation Modal */}
            <Modal
                isOpen={isRejectModalOpen}
                onRequestClose={closeRejectModal}
                contentLabel="Confirm Reject"
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                <div className="text-2xl font-bold">Confirm Rejection</div>
                <p className="text-2xl">Are you sure you want to reject this brand?</p>
                <div className="mt-3 float-right">
                    <button onClick={handleReject}
                            className="bg-red-500 hover:bg-red-700 text-white text-2xl font-bold py-2 px-4 rounded mr-2">
                        Yes
                    </button>
                    <button onClick={closeRejectModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-2 px-4 rounded">
                        No
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Brand;
