import { all } from "redux-saga/effects";
import loginSaga from "./authSagas/loginSaga";
import influencerListSaga from "./influencerSagas/influencerListSaga";
import brandListSaga from "./brandSagas/brandListSaga";
import approvedRejectedInfluencerSaga from "./influencerSagas/approvedRejectedInfluencerSaga";
import {approvedRejectedBrand} from "../actions/brandActions/approvedRejectedBrandActions";
import approvedRejectedBrandSaga from "./brandSagas/approvedRejectedBrandSaga";
import campaignListSaga from "./campaignSagas/campaignListSaga";
import approvedRejectedCampaignSaga from "./campaignSagas/approvedRejectedCampaignSaga";
import campaignByIdSaga from "./campaignSagas/campaignByIdSaga";
// import generateOtpSaga from "./authSagas/generateOtpSaga";

export default function* rootSaga() {
    yield all([
        // generateOtpSaga(),
        loginSaga(),
        influencerListSaga(),
        brandListSaga(),
        approvedRejectedInfluencerSaga(),
        approvedRejectedBrandSaga(),
        campaignListSaga(),
        approvedRejectedCampaignSaga(),
        campaignByIdSaga()

    ])
}
