import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import utilReducers from "./utilReducers/utilReducers";
import loginReducers from "./authReducers/loginReducers";
import influencerListReducers from "./influencerReducers/influencerListReducers";
import brandListReducers from "./brandReducers/brandListReducers";
import approvedRejectedInfluencerReducers from "./influencerReducers/approvedRejectedInfluencerReducers";
import approvedRejectedBrandReducers from "./brandReducers/approvedRejectedBrandReducers";
import campaignListReducers from "./campaignReducers/campaignListReducers";
import approvedRejectedCampaignReducers from "./campaignReducers/approvedRejectedCampaignReducers";
import campaignByIdReducers from "./campaignReducers/campaignByIdReducers";

const persistConfig = {
	key: 'root',
	storage,
	stateReconciler: autoMergeLevel2,
	whitelist: [
		// add reducers to persist data
		'utilReducers',
	],
};

const appReducer = combineReducers({
	utilReducers: utilReducers,
	loginReducers: loginReducers,
	influencerListReducers: influencerListReducers,
	approvedRejectedInfluencerReducers: approvedRejectedInfluencerReducers,
	brandListReducers: brandListReducers,
	approvedRejectedBrandReducers:approvedRejectedBrandReducers,
	campaignListReducers : campaignListReducers,
	approvedRejectedCampaignReducers:approvedRejectedCampaignReducers,
	campaignByIdReducers : campaignByIdReducers,

})

const rootReducers = (state, action) => {
	if (action.type === 'USER_LOGOUT') {
		return appReducer(undefined, action)
	}

	return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducers);
