export const base_url = "https://dev.paidpromo.ai/api" // User Management Service Base URL


export const endpoints = {
    GenerateOTP: "OTP/GenerateOTP", // Generate OTP
    login: "/paidpromo/auth/adminLogin",


//     influencer
    getInfluencerListByStatus: "/paidpromo/influencer/getInfluencerListByStatus?status=",
    markApprovedRejectedInfluencer: "/paidpromo/influencer/markApprovedRejectedInfluencer",

    // brand
    getBrandListByStatus: "/paidpromo/brand/getBrandListByStatus?status=",
    markApprovedRejectedBrand:"/paidpromo/brand/markApprovedRejectedBrand",

//     campaign
    getCampaignListByStatus : "/paidpromo/campaign/getCampaigListByFilter",
    markApprovedRejectedCampaign: "/paidpromo/campaign/markApprovedRejectedCampaigns",
    getCampaignById :"/paidpromo/campaign/getCampaignById/",

}
